
import React from 'react'

import '../styles/footer.sass'

const Foot = () => (
    <footer>
        <div className="footer-container">
            Source Theme by <a href={'https://umisc.info'}>UMISC</a>. © Copyright PolyCyber
        </div>
    </footer>
)

export default Foot
